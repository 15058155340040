.admin-course-search-btn-section{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.admin-course-content-form-wrap{
    display: flex;
    justify-content: space-evenly; 
}
.admin-course-input-group{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.admin-show-day{
display:none;
}

.admin-course-titles{
    width: 50%
}

.admin-course-links{
    width: 30%
}

.admin-course-duration{
    width: 15%
}
#course-content {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #course-content td, #course-content th {
    // border: 1px solid #ddd;
    padding: 8px;
    // text-transform: capitalize;
  }
  
  #course-content tr:nth-child(even){background-color: #F2F6F8;}
  
  #course-content tr:hover {background-color: #D1D5DB;}
  
  #course-content th {
    padding-top: 5px;
    padding-bottom: 5px;
    // text-align: center;
    font-family: 'Montserrat' !important;
    text-transform: capitalize;
    background-color: rgba(131, 29, 220, 0.295);
    color: #2D3945;
  }
