@import './form-validation.min.scss';

.form-group.issue, .form-group.error {
  margin-bottom: 0; }
  .form-group.issue .help-block,
  .form-group.issue .help-inline, .form-group.error .help-block,
  .form-group.error .help-inline {
    color: #F55252; }
  .form-group.issue input:not([class*='select2']),
  .form-group.issue select,
  .form-group.issue textarea, .form-group.error input:not([class*='select2']),
  .form-group.error select,
  .form-group.error textarea {
    color: #F55252 !important;
    border-color: #F55252 !important; }
  .form-group.issue input:not([class*='select2']):focus,
  .form-group.issue select:focus,
  .form-group.issue textarea:focus, .form-group.error input:not([class*='select2']):focus,
  .form-group.error select:focus,
  .form-group.error textarea:focus {
    border-color: #F55252;
    -webkit-box-shadow: 0 0 6px #F55252;
    -moz-box-shadow: 0 0 6px #F55252;
    box-shadow: 0 0 6px #F55252; }

.form-group.validate .help-block,
.form-group.validate .help-inline {
  color: #40C057; }

.form-group.validate input:not([class*='select2']),
.form-group.validate select,
.form-group.validate textarea {
  color: #40C057 !important;
  border-color: #40C057 !important; }

.form-group.validate input:not([class*='select2']):focus,
.form-group.validate select:focus,
.form-group.validate textarea:focus {
  border-color: #40C057;
  -webkit-box-shadow: 0 0 6px #40C057;
  -moz-box-shadow: 0 0 6px #40C057;
  box-shadow: 0 0 6px #40C057; }

.form-group .help-block ul {
  padding-left: 0; }
  .form-group .help-block ul li {
    list-style-type: none;
    line-height: 1.5;
    margin-top: 0.3rem; }
    .form-group .help-block ul li::before {
      content: "\e84a";
      font-size: 0.75rem;
      margin-right: 5px;
      position: relative;
      top: -1px;
      font-family: feather;
      line-height: 1; }
