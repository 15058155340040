.modalTitle {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

}

.modalTitleBlack {
  color: #2d3945
}

.modalTitleGreen {
  color: #2d3945;
}

.uploadImageForum {
  max-width: 250px;
  max-height: 200px;
  object-fit: contain;
  display: flex;
  align-self: flex-end;
}

.forumAvatar {
  height: 90px !important;
  width: 90px !important;
}

.redButton {
  color: #ed8989 !important;
  border-width: 0;
  box-shadow: 0 26px 36px -7px rgb(252, 252, 252);
  border: solid 1.5px #eaeaea;
  background-color: #fff;

}

.squareImg{
  flex-grow: 0;
  border-radius: 20px;
  background-color: #d9d9d9;
}

.purpleButton{
  background-color: #8080f1 !important;
  color: #fff !important;
  border-width: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1754px) {
  .forumAvatar {
    height: 80px !important;
    width: 80px !important;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .forumAvatar {
    height: 70px !important;
    width: 70px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .forumAvatar {
    height: 70px !important;
    width: 70px !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .forumAvatar {
    height: 70px !important;
    width: 70px !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .forumAvatar {
    height: 70px !important;
    width: 70px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .forumAvatar {
    height: 60px !important;
    width: 60px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px) {
  .forumAvatar {
    height: 50px !important;
    width: 50px !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  .forumAvatar {
    height: 50px !important;
    width: 50px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  .forumAvatar {
    height: 50px !important;
    width: 50px !important;
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  .forumAvatar {
    height: 50px !important;
    width: 50px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .forumAvatar {
    height: 40px !important;
    width: 40px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 400px) {
  .forumAvatar {
    height: 40px !important;
    width: 40px !important;
  }
}