.users-search-btn-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.users-search-btn-inner-section{
    display: flex;
    gap: 10px;
    align-items: center;
}

