@import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@400;500;600;800&display=swap');

.layout-main-container {
    display: flex;
    height: 100vh;
}
.sidebar-wrap-layout{
    width: 240px;
}

.content-wrap-layout{
    flex-grow: 1;
    padding: 10px;
    width: calc(100% - 240px);


}

// .layout-inner-content{
//     display: flex;
//     width: 100%;
//     flex-direction: column;
// }
