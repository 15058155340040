.initial-card-container{
    width: 300px;
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 30px;
    background: linear-gradient(90deg, #9834F0 26.59%, #8080F1 100%);
    box-shadow: -8px 8px 14px 0 rgba(25, 42, 70, 0.11);
    cursor: pointer;
}

.inital-screen-text{
    font-size: 35px;
    font-weight: 700;
    color:#fff;
    font-family: "Montserrat";
  }