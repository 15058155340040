@import './switchery.min.scss';
.switchery.switchery-large {
  height: 30px !important;
  width: 54px !important; }
  .switchery.switchery-large > small {
    height: 30px !important;
    width: 30px !important; }

.switchery.switchery-default {
  height: 1.5rem;
  width: 40px; }
  .switchery.switchery-default > small {
    height: 1.5rem;
    width: 1.5rem; }

.switchery.switchery-small {
  height: 18px;
  width: 30px; }
  .switchery.switchery-small > small {
    height: 18px;
    width: 18px; }

.switchery.switchery-xsmall {
  height: 14px;
  width: 24px; }
  .switchery.switchery-xsmall > small {
    height: 14px;
    width: 14px; }
