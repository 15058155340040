.forgot-pw-main-container{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 20%;
    margin-bottom: 20%;
    
}
.sign-in-sign-up-text-wrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    // width: 80%;
}
.sign-in-sign-up-text{
    color:#28B882;
    font-size: 20px;
    font-weight: 600;
}