.admin-forum-search-wrap {
    display: flex;
    justify-content: space-between;
}
.admin-main-forum-search-wrap {
    display: flex;
    justify-content: flex-end;
}

.admin-forum-search {
    width: 30%;
}
.forum-wrap {
    box-shadow: 2px 5px 20px 10px rgba(188, 188, 188, 0.18);
    margin-top: 10px;
    // height: 7 5vh;
}
.forum-student-align {
    margin-top: 30px;
}
.forum-top-wrap {
    display: flex;
    gap: 15px;
    padding: 20px;
}
.forum-body {
    display: flex;
    flex-direction: column;
    background-color: #f2f6f8;
    height:525px;
    // height: (100% - 250px)
}
