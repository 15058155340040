.input-error-text{
    margin-left: 0.5%;
    color: #d06060;
    font-family: Montserrat;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

.input-text{
    margin-left: 0.5%;
    color: black;
    font-family: Montserrat;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
   
}

#input-text{
    font-size: 12px !important;
    margin-top: 50px;
}

.input-text-title{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}