.Winspert-logo {
    width: 260.7px;
    height: 280px;
    margin: 142px 327.3px 5px 153px;
    padding: 0 0.5px 9.9px 1.1px;
}

.stackSplash {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.splashBox {
    height: 108vh;
    background-size: cover;
}