@import './ex-component-image-cropper.min.scss';

.cropper-container {
  position: relative;
  font-size: 0;
  line-height: 0;
  direction: ltr !important;
  user-select: none; }
  .cropper-container img {
    display: block;
    image-orientation: 0deg !important;
    min-width: 0 !important;
    min-height: 0 !important;
    max-width: none !important;
    max-height: none !important;
    width: 100%;
    height: 100%; }

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.cropper-wrap-box {
  overflow: hidden; }

.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0); }

.cropper-modal {
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.cropper-view-box {
  display: block;
  overflow: hidden;
  outline: 1px solid #2F8BE6;
  outline-color: rgba(47, 139, 230, 0.75);
  width: 100%;
  height: 100%; }

.cropper-dashed {
  position: absolute;
  display: block;
  border: 0 dashed #eee;
  opacity: 0.5;
  filter: alpha(opacity=50); }
  .cropper-dashed.dashed-h {
    top: 33.33333%;
    left: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
    width: 100%;
    height: 33.33333%; }
  .cropper-dashed.dashed-v {
    top: 0;
    left: 33.33333%;
    border-right-width: 1px;
    border-left-width: 1px;
    width: 33.33333%;
    height: 100%; }

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
  filter: alpha(opacity=75); }
  .cropper-center:before, .cropper-center:after {
    position: absolute;
    display: block;
    background-color: #eee;
    content: " "; }
  .cropper-center:before {
    top: 0;
    left: -3px;
    width: 7px;
    height: 1px; }
  .cropper-center:after {
    top: -3px;
    left: 0;
    width: 1px;
    height: 7px; }

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  filter: alpha(opacity=10); }

.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff; }

.cropper-line {
  background-color: #2F8BE6; }
  .cropper-line.line-e {
    top: 0;
    right: -3px;
    width: 5px;
    cursor: e-resize; }
  .cropper-line.line-n {
    top: -3px;
    left: 0;
    height: 5px;
    cursor: n-resize; }
  .cropper-line.line-w {
    top: 0;
    left: -3px;
    width: 5px;
    cursor: w-resize; }
  .cropper-line.line-s {
    bottom: -3px;
    left: 0;
    height: 5px;
    cursor: s-resize; }

.cropper-point {
  background-color: #2F8BE6;
  width: 5px;
  height: 5px;
  opacity: 0.75;
  filter: alpha(opacity=75); }
  .cropper-point.point-e {
    top: 50%;
    right: -3px;
    margin-top: -3px;
    cursor: e-resize; }
  .cropper-point.point-n {
    top: -3px;
    left: 50%;
    margin-left: -3px;
    cursor: n-resize; }
  .cropper-point.point-w {
    top: 50%;
    left: -3px;
    margin-top: -3px;
    cursor: w-resize; }
  .cropper-point.point-s {
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
    cursor: s-resize; }
  .cropper-point.point-ne {
    top: -3px;
    right: -3px;
    cursor: ne-resize; }
  .cropper-point.point-nw {
    top: -3px;
    left: -3px;
    cursor: nw-resize; }
  .cropper-point.point-sw {
    bottom: -3px;
    left: -3px;
    cursor: sw-resize; }
  .cropper-point.point-se {
    right: -3px;
    bottom: -3px;
    cursor: se-resize;
    width: 20px;
    height: 20px;
    opacity: 1;
    filter: alpha(opacity=100); }
  .cropper-point.point-se:before {
    position: absolute;
    right: -50%;
    bottom: -50%;
    display: block;
    content: " ";
    background-color: #2F8BE6;
    width: 200%;
    height: 200%;
    opacity: 0;
    filter: alpha(opacity=0); }
  @media (min-width: 768px) {
    .cropper-point.point-se {
      width: 15px;
      height: 15px; } }
  @media (min-width: 992px) {
    .cropper-point.point-se {
      width: 10px;
      height: 10px; } }
  @media (min-width: 1200px) {
    .cropper-point.point-se {
      width: 5px;
      height: 5px;
      opacity: 0.75;
      filter: alpha(opacity=75); } }

.cropper-invisible {
  opacity: 0;
  filter: alpha(opacity=0); }

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC"); }

.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0; }

.cropper-hidden {
  display: none !important; }

.cropper-move {
  cursor: move; }

.cropper-crop {
  cursor: crosshair; }

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed; }

.img-container {
  background-color: #F7F7F8;
  width: 100%;
  text-align: center;
  min-height: 200px;
  max-height: 450px;
  margin-bottom: 20px; }

.img-preview {
  background-color: #F7F7F8;
  width: 100%;
  text-align: center;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden; }
  .img-preview > img {
    max-width: 100%; }

.docs-preview {
  margin-right: -15px; }

.preview-lg {
  width: 263px;
  height: 148px; }

.preview-md {
  width: 139px;
  height: 78px; }

.preview-sm {
  width: 69px;
  height: 39px; }

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0; }

@media (min-width: 768px) {
  .img-container {
    min-height: 450px; } }
