@media screen and (min-device-width: 320px) and (max-device-width: 960px) {
    .student-search-btn-section{
        display: flex;
        flex-direction: column;
        gap: 15px;
        // align-items: center;
    }
    .student-search-btn-inner-section{
        display: flex;
        flex-direction: column;
        gap: 10px;
        // align-items: center;
    }
}
