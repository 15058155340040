.input-phone-number input:focus{
    outline: none !important;
    border:none !important;
    background-color: #f2f6f8;
    box-shadow: none !important;
}

.input-phone-number input{
    outline: none !important;
    border: none !important;
    background-color: #f2f6f8;
    box-shadow: none !important;
}