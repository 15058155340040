@import "./verticalOverlayMenuMin.scss";

.vertical-overlay-menu .content {
  margin-left: 0; }

.vertical-overlay-menu .navbar .navbar-header {
  width: 175px; }
  .vertical-overlay-menu .navbar .navbar-header .nav-item {
    padding-top: .25rem;
    padding-bottom: .25rem; }

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-header {
  float: left;
  width: auto; }

.vertical-overlay-menu .wrapper {
  top: 4rem; }

.vertical-overlay-menu .app-sidebar .navigation li a {
  letter-spacing: 0.6px;
  font-size: .95rem; }

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: opacity .25s, transform .25s;
  width: 250px;
  left: -250px; }
  .vertical-overlay-menu .main-menu .navigation .navigation-header .feather.icon-minus {
    display: none; }
  .vertical-overlay-menu .main-menu .navigation > li > a > i {
    font-size: 1.2rem;
    margin-right: 12px;
    float: left; }
    .vertical-overlay-menu .main-menu .navigation > li > a > i:before {
      transition: 200ms ease all; }
  .vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\f105";
    font-family: 'FontAwesome';
    font-size: 1rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 10px;
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after {
    transform: rotate(90deg); }
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom: 55px; }
  .vertical-overlay-menu .main-menu .main-menu-footer {
    width: 250px; }
  .vertical-overlay-menu .main-menu.menu-fixed {
    position: fixed;
    top: 4rem;
    height: calc(100% - 4rem); }

.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  transform: translate3d(250px, 0, 0);
  transition: opacity .25s, transform .25s;
  z-index: 998; }

.vertical-overlay-menu.menu-flipped .main-menu {
  right: -250px;
  left: inherit; }

.vertical-overlay-menu.menu-flipped .navbar .navbar-container {
  margin: 0;
  margin-right: 250px; }

.vertical-overlay-menu.menu-flipped .navbar .navbar-header {
  float: right; }

.vertical-overlay-menu.menu-flipped.menu-open .main-menu {
  transform: translate3d(-250px, 0, 0); }

.vertical-overlay-menu.navbar-sticky .main-panel {
  margin-top: 0; }

.vertical-overlay-menu:not([class*='application']) .main-panel .main-content {
  min-height: calc((var(--vh, 1vh) * 100) - 7.44rem); }

.vertical-overlay-menu[class*='application'] .main-panel .main-content {
  min-height: calc((var(--vh, 1vh) * 100) - 8.45rem); }

.vertical-overlay-menu footer.footer.fixed-bottom {
  z-index: 999; }

.vertical-overlay-menu.vertical-overlay-menu.boxed-layout.menu-open .main-menu {
  transform: translate3d(250px, 0, 0);
  margin-left: 7px; }

@media (max-width: 1199.98px) {
  .vertical-overlay-menu .navbar-header .navbar-toggle {
    margin: 12px 17px 0 6px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .vertical-overlay-menu .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; } }

@media (max-width: 991.98px) {
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom: 0px; } }

@media screen and (max-width: 575px) {
  .vertical-overlay-menu.boxed-layout.menu-open .main-menu {
    transform: translate3d(250px, 0, 0) !important;
    margin-left: 0 !important; } }

[data-textdirection="rtl"] body.vertical-layout.vertical-overlay-menu.menu-collapsed .main-menu .navigation > li > a {
  padding: 14px 16px !important; }

[data-textdirection="rtl"] body.vertical-layout.vertical-overlay-menu.menu-collapsed .main-menu .navigation > li.navigation-header .feather.icon-minus {
  padding: 12px 0px; }

[data-textdirection="rtl"] body.vertical-layout.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
  content: '\f104'; }

[data-textdirection="rtl"] body.vertical-layout .knob-center-icon {
  margin-left: -99px; }
