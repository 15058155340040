.announment-content-main-container{
 display: flex;
 justify-content: space-between;
 width:100%;
 
}
.announment-content-container{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
// gap: 10px;
}



// .announment-form-wrap{
// width: %;
// }

.announcement-img{
    width: 100%;
    height: auto;
}